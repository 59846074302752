///
//  Profile pic
//
.profile-pic {
  border: 0.25rem solid #ffffff;
  border-radius: 50%;
}

///
//  Intro and Content
//
@media only screen and (min-width: 1200px) {
  .intro {
    position: fixed;
    width: 400px;
    padding-right: 65px;
    flex-shrink: 0;
  }

  .content {
    margin-left: 400px;
    padding-top: 197px;
  }
}

///
// Privacy page
//
#privacy-page {
  p {
    margin-bottom: 1em;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 2em;
    margin-bottom: 0.5em;
    font-weight: 700;
  }
}

///
//  Type
//
.text-xs {
  font-size: modular-scale(-2);
}
.text-sm {
  font-size: modular-scale(-1);
}
.text-md {
  font-size: modular-scale(0);
}
.text-lg {
  font-size: modular-scale(1);
}
.text-xl {
  font-size: modular-scale(2);
}
.text-2xl {
  font-size: modular-scale(3);
}
.font-bold {
  font-weight: 700;
}

///
//  Spacing
//
.p-0 {
  padding: 0;
}
.p-1 {
  padding: modular-scale(0);
}
.p-2 {
  padding: modular-scale(1);
}
.p-3 {
  padding: modular-scale(2);
}
.p-4 {
  padding: modular-scale(3);
}
.p-5 {
  padding: modular-scale(4);
}
.p-6 {
  padding: modular-scale(5);
}
.p-7 {
  padding: modular-scale(6);
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: modular-scale(0);
}
.pt-2 {
  padding-top: modular-scale(1);
}
.pt-3 {
  padding-top: modular-scale(2);
}
.pt-4 {
  padding-top: modular-scale(3);
}
.pt-5 {
  padding-top: modular-scale(4);
}
.pt-6 {
  padding-top: modular-scale(5);
}
.pt-7 {
  padding-top: modular-scale(6);
}

.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: modular-scale(0);
}
.pr-2 {
  padding-right: modular-scale(1);
}
.pr-3 {
  padding-right: modular-scale(2);
}
.pr-4 {
  padding-right: modular-scale(3);
}
.pr-5 {
  padding-right: modular-scale(4);
}
.pr-6 {
  padding-right: modular-scale(5);
}
.pr-7 {
  padding-right: modular-scale(6);
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: modular-scale(0);
}
.pb-2 {
  padding-bottom: modular-scale(1);
}
.pb-3 {
  padding-bottom: modular-scale(2);
}
.pb-4 {
  padding-bottom: modular-scale(3);
}
.pb-5 {
  padding-bottom: modular-scale(4);
}
.pb-6 {
  padding-bottom: modular-scale(5);
}
.pb-7 {
  padding-bottom: modular-scale(6);
}

.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: modular-scale(0);
}
.pl-2 {
  padding-left: modular-scale(1);
}
.pl-3 {
  padding-left: modular-scale(2);
}
.pl-4 {
  padding-left: modular-scale(3);
}
.pl-5 {
  padding-left: modular-scale(4);
}
.pl-6 {
  padding-left: modular-scale(5);
}
.pl-7 {
  padding-left: modular-scale(6);
}

.m-0 {
  margin: 0;
}
.m-1 {
  margin: modular-scale(0);
}
.m-2 {
  margin: modular-scale(1);
}
.m-3 {
  margin: modular-scale(2);
}
.m-4 {
  margin: modular-scale(3);
}
.m-5 {
  margin: modular-scale(4);
}
.m-6 {
  margin: modular-scale(5);
}
.m-7 {
  margin: modular-scale(6);
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: modular-scale(0);
}
.mt-2 {
  margin-top: modular-scale(1);
}
.mt-3 {
  margin-top: modular-scale(2);
}
.mt-4 {
  margin-top: modular-scale(3);
}
.mt-5 {
  margin-top: modular-scale(4);
}
.mt-6 {
  margin-top: modular-scale(5);
}
.mt-7 {
  margin-top: modular-scale(6);
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: modular-scale(0);
}
.mr-2 {
  margin-right: modular-scale(1);
}
.mr-3 {
  margin-right: modular-scale(2);
}
.mr-4 {
  margin-right: modular-scale(3);
}
.mr-5 {
  margin-right: modular-scale(4);
}
.mr-6 {
  margin-right: modular-scale(5);
}
.mr-7 {
  margin-right: modular-scale(6);
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: modular-scale(0);
}
.mb-11 {
  margin-bottom: modular-scale(-2);
}
.mb-2 {
  margin-bottom: modular-scale(1);
}
.mb-3 {
  margin-bottom: modular-scale(2);
}
.mb-4 {
  margin-bottom: modular-scale(3);
}
.mb-5 {
  margin-bottom: modular-scale(4);
}
.mb-6 {
  margin-bottom: modular-scale(5);
}
.mb-7 {
  margin-bottom: modular-scale(6);
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: modular-scale(0);
}
.ml-2 {
  margin-left: modular-scale(1);
}
.ml-3 {
  margin-left: modular-scale(2);
}
.ml-4 {
  margin-left: modular-scale(3);
}
.ml-5 {
  margin-left: modular-scale(4);
}
.ml-6 {
  margin-left: modular-scale(5);
}
.ml-7 {
  margin-left: modular-scale(6);
}
