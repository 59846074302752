// Modular scale
$golden:           1.618;
$minor-second:     1.067;
$major-second:     1.125;
$minor-third:      1.2;
$major-third:      1.25;
$perfect-fourth:   1.333;
$augmented-fourth: 1.414;
$perfect-fifth:    1.5;
$minor-sixth:      1.6;
$major-sixth:      1.667;
$minor-seventh:    1.778;
$major-seventh:    1.875;
$octave:           2;
$major-tenth:      2.5;
$major-eleventh:   2.667;
$major-twelfth:    3;
$double-octave:    4;

$modular-scale-ratio: $perfect-fourth !default;
$modular-scale-base: 1em !default;

@function modular-scale($increment, $value: $modular-scale-base, $ratio: $modular-scale-ratio) {
  $v1: nth($value, 1);
  $v2: nth($value, length($value));
  $value: $v1;

  // scale $v2 to just above $v1
  @while $v2 > $v1 {
    $v2: ($v2 / $ratio); // will be off-by-1
  }
  @while $v2 < $v1 {
    $v2: ($v2 * $ratio); // will fix off-by-1
  }

  // check AFTER scaling $v2 to prevent double-counting corner-case
  $double-stranded: $v2 > $v1;

  @if $increment > 0 {
    @for $i from 1 through $increment {
      @if $double-stranded and ($v1 * $ratio) > $v2 {
        $value: $v2;
        $v2: ($v2 * $ratio);
      } @else {
        $v1: ($v1 * $ratio);
        $value: $v1;
      }
    }
  }

  @if $increment < 0 {
    // adjust $v2 to just below $v1
    @if $double-stranded {
      $v2: ($v2 / $ratio);
    }

    @for $i from $increment through -1 {
      @if $double-stranded and ($v1 / $ratio) < $v2 {
        $value: $v2;
        $v2: ($v2 / $ratio);
      } @else {
        $v1: ($v1 / $ratio);
        $value: $v1;
      }
    }
  }

  @return $value;
}

// Screen (media query)
$breakp1: 30em !default;
$breakp2: 45em !default;
$breakp3: 60em !default;
$breakp4: 75em !default;

@mixin screen($media) {
  @if $media == medium {
    @media screen and (min-width: $breakp1) {
      @content;
    }
  }
  @else if $media == large {
    @media screen and (min-width: $breakp2) {
      @content;
    }
  }
  @else if $media == big {
    @media screen and (min-width: $breakp3) {
      @content;
    }
  }
  @else if $media == huge {
    @media screen and (min-width: $breakp4) {
      @content;
    }
  }
}