*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// html {
//     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }

body {
  color: $black;
  background: #fafafa;
  font-family: $sans;
  font-weight: normal;
  font-size: modular-scale(0);
  line-height: 1.5;
}

a {
  padding-bottom: 0.25rem;
  text-decoration: none;
  border-bottom: 0.25rem solid $primary;
  color: $black;
  //   &:visited,
  //   &:active {
  //     color: lighten($black, 20);
  //   }
  &:hover {
    color: $primary;
  }
  transition: color 0.2s ease-in-out;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

hr {
  border: 0.125rem solid $secondary;
}

strong {
  font-weight: 700;
}

td {
  min-width: 6.25rem;
}

ul {
  list-style-type: disc;
  padding-left: 1em;
}
